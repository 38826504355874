<template>
    <div>
        <el-table :data="list" size="small" border class="table"
                  height="calc(100vh - 295px)" :span-method="objectSpanMethod" v-loading="loading">
            <el-table-column prop="revdName" label="审核模块"></el-table-column>
            <el-table-column prop="revdEnable" label="启用状态">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.revdEnable" :active-value="0" :inactive-value="1"
                               @change="enabledChange(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="revdStep" label="审核步骤">
                <template slot-scope="scope">
                    {{scope.row.revdStep}}级
                </template>
            </el-table-column>
            <el-table-column prop="stepUserName" label="审核角色"></el-table-column>
            <el-table-column fixed="right" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" v-if="hasPermission('sys:set:edit')"
                               @click="addEdit(scope.row, 'edit')">修改
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <AuditForm ref="auditForm" @refreshDataList="getList"></AuditForm>
    </div>
</template>

<script>
    import AuditForm from './auditForm'

    export default {
        props: {
            activeTab: Number,
        },
        components: {
            AuditForm
        },
        data() {
            return {
                loading: false,
                list: [],
                mergerItems: [
                    {
                        columnIndex: 0,
                        spanArr: [],
                        pos: 0,
                        prop: 'revdName'
                    },
                    {
                        columnIndex: 1,
                        spanArr: [],
                        pos: 0,
                        prop: 'revdName'
                    },
                    {
                        columnIndex: 4,
                        spanArr: [],
                        pos: 0,
                        prop: 'revdName'
                    }
                ]
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true
                let api = this.api.record.setAuditList,
                    p = {}
                if (this.activeTab == 1 || this.activeTab == 2) {
                    api = this.api.collection.collectionrevdListRevd
                }
                if (this.activeTab == 1) {
                    p.revdType = 0
                }
                if (this.activeTab == 2) {
                    p.revdType = 1
                }
                this.$axios(api, p).then(res => {
                    if (res.status) {
                        this.list = res.data.records
                        if (this.activeTab == 1 || this.activeTab == 2) {
                            this.list = res.data
                        }
                        this.getSpanArr(this.list, this.mergerItems)
                    } else {
                        this.$message.error('查询失败')
                        this.loading = false
                    }
                })
            },
            // 启用
            enabledChange(row) {
                this.loading = true
                let api = this.api.record.listRevdEnable,
                    methodType = 'get'
                if (this.activeTab == 1 || this.activeTab == 2) {
                    api = this.api.collection.enable
                    methodType = 'post'
                }
                this.$axios(api, {
                    id: row.id,
                    revdEnable: row.revdEnable,
                }, methodType).then(res => {
                    if (res.status) {
                        this.$message.success('操作成功')
                    } else {
                        this.$message.error(res.msg)
                    }
                    this.getList()
                    this.loading = false
                })
            },
            // 修改
            addEdit(row) {
                this.$refs.auditForm.init(row, this.activeTab)
            },
            getSpanArr(data, array) {//循环数据处理
                for (let n = 0; n < array.length; n++) {
                    array[n].spanArr = []//数据清空(重新调此方法的时候需要清空上一次的数据)
                    for (let i = 0; i < data.length; i++) {
                        if (i === 0) {
                            array[n].spanArr.push(1);
                            array[n].pos = 0;
                        } else {
                            // 判断当前元素与上一个元素是否相同
                            if (data[i][array[n].prop] === data[i - 1][array[n].prop]) {
                                array[n].spanArr[array[n].pos] += 1;
                                array[n].spanArr.push(0);
                            } else {
                                array[n].spanArr.push(1);
                                array[n].pos = i;
                            }
                        }
                    }
                }
                this.$nextTick(() => {
                    this.loading = false
                })
            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
                    let item = this.mergerItems.find(item => item.columnIndex === columnIndex)
                    const _row = item.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col,
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
